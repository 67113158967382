import React, {useState, useEffect} from 'react';
import logo from './images/logo2.png'
import FlexRow from '@bit/fergc.integrarh.flex-row';
import FlexColumn from '@bit/fergc.integrarh.flex-column';
import Timeline from '@bit/fergc.integrarh.timeline';
import SectionHeader from '@bit/fergc.integrarh.section-header';
import {useSession} from './Hooks';
import HttpRequest from './shared/HttpRequest'
import ProspectoProcesoStep from './shared/ProspectoProcesoStep';
import EventsCalendar from '@bit/fergc.integrarh.events-calendar';
import {Button} from 'reactstrap'
import moment from 'moment';

export const CALENDAR_EVENT_LABELS = {
    entrevistaTelefonica: 'Entrevista telefónica',
    entrevistaDigital: 'Entrevista digital',
    evaluacionPsicometrica: 'Evaluación psicométrica',
    estudioSocioeconomico: 'Estudio socioeconómico',
    entrevistaConEmpresa: 'Entrevista con empresa',
    postAsignacion: 'Post asignación',
    cierreDefinitivo: 'Cierre definitivo',
    entrevistaPersonal: 'Entrevista personal'
}

function handleEventsLoaded(evs) {
    const newEvents = [];
    for(let e of evs) {
        const d = moment(e.day);
        const label = `${d.month()}-${d.date()}`
        if(!newEvents[label]) {
            newEvents[label] = [];
        }
        const exists = newEvents[label].find(ce => ce._id == e._id);
        if(exists) {
            continue;
        }
        newEvents[label].push({
            _id: e._id,
            eventType: e.eventType      
        })
    }
    return newEvents;     
}   


function CalendarListItem({event}) {
    if(!event) {
        return null;
    }
    const formatted = moment(event.google.start.dateTime).format("dddd, MMMM DD YYYY, h:mm:ss a");
    return (
        <li>
            <span style={{fontWeight:'bold'}}>
                {CALENDAR_EVENT_LABELS[event.eventType]}
            </span> <br/>
            {`${formatted[0].toUpperCase()}${formatted.slice(1)}`}
            {
                event.videoCallData && event.videoCallData.joinUrl ? 
                <React.Fragment>
                    <br/><a href={event.videoCallData.joinUrl} target="blank">Videoconferencia</a>
                </React.Fragment> : null
            }
            <br/>
        </li>
    )
}

export default function Main() {

    const [token, login, logout] = useSession();
    const [prospecto, setProspecto] = useState({
        nombre:'',
        proceso:{
            infoGeneral:{},
            pasos:[],
            resultado: {}
        }
    });
    const [eventList, setEventList] = useState([])

    useEffect(() => {
        async function fetchProspecto() {
            const response = await HttpRequest.get('/prospecto');
            const p = response.data;
            if(!p.proceso.resultado) {
                p.proceso.resultado = {};
            }
            setProspecto(p);
        }
        fetchProspecto();
        fetchProspectoEvents();
    }, [])


    async function fetchProspectoEvents() {
        const response = await HttpRequest.get(`/eventos/all`);
        setEventList(response.data);        
    }

    function handleEventRejected() {
        fetchProspectoEvents();
    }

    function handlePasoChange(index) {
        return function(paso) {
            const pasos = [...prospecto.proceso.pasos];
            pasos[index] = paso;
            const proceso = {...prospecto.proceso, pasos}
            setProspecto({...prospecto, proceso})
        }
    }

    return (
        <div>
            <div className="TopBar">
                <div className="col">
                    <img src={logo} style={{width:'150px'}} alt="logo"/>
                </div>
                <div className="col justify-content-end">
                    <div className="row justify-content-end">
                        <div>{prospecto.email}</div>
                    </div>
                    <div className="row justify-content-end">
                        <div>
                            <Button color="link"
                            onClick={logout}>Cerrar sesión</Button>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="MainArea">
                <div className="MainAreaContent">                
                    <FlexColumn>
                        <SectionHeader textAlign="center" subtitle="Monitorea aquí tu proceso de reclutamiento">
                            BIENVENIDO A INTEGRA RH
                        </SectionHeader>
                        <FlexRow justifyContent="center">
                            <Timeline infoGeneral={prospecto.proceso.infoGeneral}
                                pasos={prospecto.proceso.pasos}
                                resultado={prospecto.proceso.resultado}></Timeline>
                        </FlexRow>
                        <FlexRow alignItems="start">
                            <FlexColumn style={{padding:'20px'}}>
                                <SectionHeader textAlign="center">
                                    PROCESO
                                </SectionHeader>
                                <br/>
                                <ProspectoProcesoStep numero="1" paso={prospecto.proceso.infoGeneral}></ProspectoProcesoStep>
                                {
                                    prospecto.proceso.pasos.map((p, i) => (
                                        <ProspectoProcesoStep
                                            key={p.nombre}
                                            numero={i + 2}
                                            paso={p}
                                            onChange={handlePasoChange(i)}
                                            onEventRejected={handleEventRejected}></ProspectoProcesoStep>
                                    ))
                                }     
                                <ProspectoProcesoStep 
                                    numero={prospecto.proceso.pasos.length + 2} 
                                    paso={prospecto.proceso.resultado}></ProspectoProcesoStep>                           
                            </FlexColumn>
                            <FlexColumn style={{alignItems:'center'}}>
                                <SectionHeader textAlign="center" subtitle="Fechas asignadas para entrevistas">
                                    CALENDARIO
                                </SectionHeader>
                                <br/>
                                <ul>
                                    {
                                        eventList.map((e, i) => (
                                            <CalendarListItem key={i} event={e}></CalendarListItem>
                                        ))
                                    }
                                </ul>
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                    {/* <Route exact path="/requisiciones" component={Requisiciones}/>*/}
                </div> 
            </div>
        </div>
    )
}