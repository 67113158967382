import React from 'react';
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import styles from './LoginOptions.module.css';

export default function LoginOptions({options, onSelected}) {

    function handleClick(token) {
        return function() {
            onSelected(token)
        }
    }

    return (
        <FlexColumn>
            <div className={styles.header}></div>
            <div className={styles.container}>
                <p>
                Tu correo está registrado en más de un proceso de reclutamiento, 
                elige la vacante que quieres visualizar
            </p>
            <p>
                Para cambiar de vacante tendrás que cerrar sesión y volver a iniciar 
                sesión para seleccionar otra vacante
            </p>
            {
                options.map(o => (
                    <div key={o.token} 
                        className={styles.option}
                        onClick={handleClick(o.token)}>{o.nombrePuesto}</div>
                ))
            }
            </div>            
        </FlexColumn>
    )

}