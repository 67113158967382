import React, {useEffect} from 'react';
import {useFetch} from './Hooks'
import {Button} from 'reactstrap';
import FileSaver from 'file-saver';


export default function FileLink({link, ...rest}) {

    const [fetching, data, doFetch] = useFetch(null)

    function handleClick(event) {
        event.preventDefault();
        event.stopPropagation();        
        doFetch(link, {
            responseType: 'blob'
        });
    }

    useEffect(() => {
        if(data) {
            FileSaver.saveAs(data, 'resultado psicometrico.pdf');
        }
    }, [data])

    return (
        <Button disabled={fetching} onClick={handleClick} {...rest}></Button>
    )

}