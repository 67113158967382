import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '@bit/fergc.integrarh.fast-input'
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'
import DatosGeneralesFormGenerales from './DatosGeneralesFormGenerales';
import DatosGeneralesFormDireccion from './DatosGeneralesFormDireccion';
import DatosGeneralesFormExperienciaLaboral from './DatosGeneralesFormExperienciaLaboral';
import DatosGeneralesFormFormacionAcademica from './DatosGeneralesFormFormacionAcademica';
import DatosGeneralesFormOtrosEstudios from './DatosGeneralesFormOtrosEstudios'
import DatosGeneralesFormIdiomas from './DatosGeneralesFormIdiomas'
import DatosGeneralesFormSoftware from './DatosGeneralesFormSoftware'
import DatosGeneralesFormExtra from './DatosGeneralesFormExtra'
import HttpRequest from '../HttpRequest'
import FileInput from '@bit/fergc.integrarh.file-input';

function setIfNotNull(setValue, value) {
    if(value) {
        setValue(value);
    }
}

export default function DatosGeneralesFormFields({isOpen, onComplete, onCancel}) {

    const [working, setWorking] = useState(false);

    const [generales, setGenerales] = useState({
        paterno:'',
        materno:'',
        nombre:'',
        fechaNacimiento: new Date(),
        lugarNacimiento: '',
        estadoCivil: '',
        nss: '',
        rfc: '',
        curp: ''
    })

    const [direccion, setDireccion] = useState({
        calle: '',
        numero: '',
        colonia: '',
        estado: '',
        municipio: ''
    })

    const [experiencia, setExperiencia] = useState([{
        empresa: '',
        puesto: '',
        fechaInicio: new Date(),
        fechaTermino: new Date(),
        actividadesClave: '',
        logros: ''
    }])

    const [formacionAcademica, setFormacionAcademica] = useState([{
        nivelEstudios: '',
        institucion: '',
        estatus: '',
        carrera: '',
        fechaInicio: new Date(),
        fechaTermino: new Date(),
        actual: false
    }])

    const [otrosEstudios, setOtrosEstudios] = useState({
        tipoEstudio: '',
        institucion: '',
        fechaInicio: new Date(),
        fechaTermino: new Date(),
        actual: false
    })

    const [idiomas, setIdiomas] = useState([{
        idioma:'',
        nivelDominio:'',
        porcentaje:''
    }])

    const [software, setSoftware] = useState([{
        software:'',
        nivelDominio:''
    }])

    const [extra, setExtra] = useState({
        competenciasLaborales: '',
        areasInteres: ''
    })

    const [savedFiles, setSavedFiles] = useState({
        comprobanteEstudios: null,
        comprobanteDomicilio: null,
        curriculum: null
    })

    const [files, setFiles] = useState({
        comprobanteEstudios: null,
        comprobanteDomicilio: null,
        curriculum: null
    })
    

    useEffect(() => {
        async function fetchData() {
            const response = await HttpRequest.get(`/prospecto/proceso/infoGeneral`);
            const {result, data} = response.data;
            if(result === 'ok') {                
                const datos = data.datos;
                setIfNotNull(setGenerales, datos.generales);
                setIfNotNull(setDireccion, datos.direccion);
                setIfNotNull(setExperiencia, datos.experiencia);
                setIfNotNull(setFormacionAcademica, datos.formacionAcademica);
                setIfNotNull(setOtrosEstudios, datos.otrosEstudios);
                setIfNotNull(setIdiomas, datos.idiomas);
                setIfNotNull(setSoftware, datos.software);
                setIfNotNull(setExtra, datos.extra);

                // if(datos.comprobanteEstudios || datos.comprobanteDomicilio) {
                    setSavedFiles({
                        comprobanteEstudios: datos.comprobanteEstudios,
                        comprobanteDomicilio: datos.comprobanteDomicilio,
                        curriculum: datos.curriculum
                    })
                // }
                // e.fecha = formatDate(data.datos.fecha);
                
            }
        }
        if(isOpen) {
            console.log('fetching infoGeneral ')
            fetchData();
        }     
    }, [isOpen])

    function handleSubChange(setState) {
        return function(data) {
            setState(data);
        }
    }

    function handleFileChange(name) {
        return function(file) {
            setFiles({...files, [name]:file})
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        const formData = new FormData();
        formData.append('comprobanteDomicilio', files.comprobanteDomicilio || []);
        formData.append('comprobanteEstudios', files.comprobanteEstudios || []);
        formData.append('curriculum', files.curriculum || [])
        formData.append('stepData', JSON.stringify({
            stepName:'infoGeneral',
            stepData: {
                generales,
                direccion,
                experiencia,
                formacionAcademica,
                otrosEstudios,
                idiomas,
                software,
                extra
            }
        }));
        const response = await HttpRequest.postMultipart(`/prospecto/proceso/infoGeneral`, formData)        
        setWorking(false);
        onComplete(response.data);
    }

    return (
            <Form onSubmit={handleSubmit}>
                <FlexColumn>                            
                    <DatosGeneralesFormGenerales 
                        value={generales} 
                        onChange={handleSubChange(setGenerales)}></DatosGeneralesFormGenerales>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormDireccion
                        value={direccion}
                        onChange={handleSubChange(setDireccion)}></DatosGeneralesFormDireccion>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormExperienciaLaboral
                        value={experiencia}
                        onChange={handleSubChange(setExperiencia)}></DatosGeneralesFormExperienciaLaboral>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormFormacionAcademica
                        value={formacionAcademica}
                        onChange={handleSubChange(setFormacionAcademica)}></DatosGeneralesFormFormacionAcademica>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormOtrosEstudios
                        value={otrosEstudios}
                        onChange={handleSubChange(setOtrosEstudios)}></DatosGeneralesFormOtrosEstudios>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormIdiomas
                        value={idiomas}
                        onChange={handleSubChange(setIdiomas)}></DatosGeneralesFormIdiomas>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormSoftware
                        value={software}
                        onChange={handleSubChange(setSoftware)}></DatosGeneralesFormSoftware>
                    <hr/>
                    <br/>
                    <DatosGeneralesFormExtra
                        value={extra}
                        onChange={handleSubChange(setExtra)}></DatosGeneralesFormExtra>
                    <br/>
                    <FormGroup>
                        <Label>Comprobante de estudios</Label>
                        <FileInput maxSize={10} 
                        fileId="comprobanteEstudios"
                        fileUrl={savedFiles.comprobanteEstudios}
                        filePath="/prospecto/files/sign/comprobanteEstudios/"
                        onChange={handleFileChange('comprobanteEstudios')}></FileInput>
                    </FormGroup>      
                    <br/>
                    <FormGroup>
                        <Label>Comprobante de domicilio</Label>
                        <FileInput maxSize={10} 
                        fileId="comprobanteDomicilio"
                        fileUrl={savedFiles.comprobanteDomicilio}
                        filePath="/prospecto/files/sign/comprobanteDomicilio/"
                        onChange={handleFileChange('comprobanteDomicilio')}></FileInput>
                    </FormGroup>      
                    <br/>
                    <FormGroup>
                        <Label>Curriculum</Label>
                        <FileInput maxSize={10} 
                        fileId="curriculum"
                        fileUrl={savedFiles.curriculum}
                        filePath="/prospecto/files/sign/curriculum/"
                        onChange={handleFileChange('curriculum')}></FileInput>
                    </FormGroup>                
                    <FlexRow justifyContent='flex-end' className="FormButtons">
                        <Button type="submit" color="primary" disabled={working}>Guardar</Button>
                        <Button color="secondary" onClick={onCancel} disabled={working}>Cancelar</Button>
                    </FlexRow>
                </FlexColumn>
            </Form>
    )
}