import React from 'react';
import {Route} from 'react-router-dom'
import './App.css';
import Main from './Main'
import {BrowserRouter as Router, Redirect} from 'react-router-dom';
import Login from './Login';
import VerifyLogin from './VerifyLogin';
import PrivateRoute from './shared/PrivateRoute'

function App() {
  return (
    <Router>
      <div className="App">
          <Route exact path="/">
            {
              localStorage.getItem('jwt') ? 
              <Redirect to="/app"></Redirect> :
              <Redirect to="/login"></Redirect>
            }
          </Route>
          <Route path="/login/:token?" component={Login}></Route>
          <PrivateRoute path="/app" component={Main}></PrivateRoute>
      </div>
    </Router>
  );
}

export default App;
