import React, {useState, useEffect} from 'react';
import FlexRow from '@bit/fergc.integrarh.flex-row'
import {EventDate} from '../shared/ProspectoUtils'
import FastInput from '@bit/fergc.integrarh.fast-input'
import {Button, Collapse, FormGroup, Label} from 'reactstrap';
import ConfirmEventModal from './ConfirmEventModal';
import CancelEventModal from './CancelEventModal';
import {useToggle, useFetchOnLoad} from './Hooks';
import FileLink from './FileLink';
import ConfirmPsicometricoModal from './ConfirmPsicometricoModal'

export default function ConfirmPsicometricoMoreInfo({paso, onChange, onAccept, isOpen}) {

    const [showConfirmModal, toggleConfirmModal] = useToggle(false)
    const [fetching, response] = useFetchOnLoad(`/prospecto/proceso/evaluacionPsicometrica`, isOpen, {})

    function handleConfirmDone() {
        toggleConfirmModal();
        onAccept({...paso, status:'done'})
    }

    const data = response && response.result === 'ok' ?
        response.data.datos : {}

    return (
        <React.Fragment>
            <FileLink link={`/prospecto/file/${data.archivo}`}>Descargar</FileLink>
            {
                paso.status === 'waitingConfirmation' ?
                <FlexRow justifyContent='flex-end' className="ProspectoStepCollapsibleButtons">
                    <Button className="btn-transparent" 
                        disabled={fetching}
                        onClick={toggleConfirmModal}>Aceptar</Button>
                </FlexRow> : null
            }
            <ConfirmPsicometricoModal
                isOpen={showConfirmModal}
                paso={paso}
                onDone={handleConfirmDone}
                onCancel={toggleConfirmModal}/>
        </React.Fragment>
    )
}