import React, {useState, useEffect} from 'react';
import {Button, Input, FormGroup, Label} from 'reactstrap';
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'
import {useFetch} from '../Hooks'

export default function EvaluacionPsicometricaFormFields({requisito, isOpen, onComplete, onCancel}) {
    
    function handleClick() {
        onComplete();
    }

    return (
        <FlexColumn>
            <h6>Datos de acceso</h6>
            <FlexRow>
                <FormGroup>
                    <Label>Portal</Label>
                    <div className="FlatBox Bold">www.evaluaciones.mx</div>
                </FormGroup>
                <FormGroup>
                    <Label>Clave</Label>
                    <div className="FlatBox Bold">{requisito.clave}</div>
                </FormGroup>
            </FlexRow>
            <br/>
            <FormGroup>
                <Label>Con estos datos podrá</Label>
                <div className="FlatBox">
                    <br/>
                    <ol>
                        <li>
                            Ingresar al portal www.evaluaciones.mx después de ingresar al portal se accede 
                            usando la clave: {requisito.clave}
                        </li>
                        <li>
                            Aparecerán sus datos personales, se deben de contestar los datos que hacen falta 
                            y en caso de ser necesario modificar los datos que sean incorrectos 
                            (el correo es el único dato que no se debe de modificar).
                        </li>
                        <li>
                            Aparecerá un listado de las pruebas psicométricas que se deben de contestar, 
                            antes de contestar las pruebas psicométricas se debe de contestar las practicas, 
                            si no se contesta primero la practica no se podrá contestar la prueba psicométrica. 
                            La practica no tiene valor alguno solo es para comprender las instrucciones y entender 
                            como se llevara a cabo la prueba psicométrica.
                        </li>
                        <li>
                            Contestar las pruebas programadas    
                        </li>
                    </ol>
                    <br/>
                </div>
                <br/>
                <FlexRow justifyContent='center'>
                    <Button className="btn-white" onClick={handleClick}>ENTENDIDO</Button>
                </FlexRow>
            </FormGroup>
        </FlexColumn>
    )

}