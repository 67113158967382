import React, {useState, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest'

export function useToggle(initialState) {

    const [isOpen, setIsOpen] = useState(initialState);

    function toggle() {
        setIsOpen(!isOpen);
    }

    return [isOpen, toggle]

}

export function usePostHttpRequest() {
    const [working, setWorking] = useState(false);
    const [response, setResponse] = useState(null)

    async function doPost(path, body, multipart) {
        setWorking(true);
        let result = null;
        if(multipart) {
            result = await HttpRequest.postMultipart(path, body)
        } else {
            result = await HttpRequest.post(path, body)
        }        
        setResponse(result);
        setWorking(false);
    }

    return [working, doPost, response];
    
}

export function useFetchOnLoad(path, load, initialState) {
    const [working, setWorking] = useState(false);
    const [data, setData] = useState(initialState);

    useEffect(() => {
        if(!load) {
            return;
        }

        async function doFetch() {
            setWorking(true);
            const response = await HttpRequest.get(path)
            setData(response.data);
            console.log(response.data)
            setWorking(false);               
        }
        doFetch();   
    }, [load])    

    return [working, data]
}

export function useFetch(initialState) {
    const [working, setWorking] = useState(false);
    const [data, setData] = useState(initialState);

    async function doFetch(path, options) {
        setWorking(true);
        const response = await HttpRequest.get(path, options)
        setData(response.data);
        setWorking(false);        
    }

    return [working, data, doFetch]
}
