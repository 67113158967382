import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label,
    TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import FastInput from '@bit/fergc.integrarh.fast-input'
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'

export default function DatosGeneralesFormIdiomas({value, onChange}) {

    const [tab, setTab] = useState(0)

    function handleChange(name, i) {
        return function(event) {
            const newValue = value.slice();
            newValue[i][name] = event.target.value;
            onChange(newValue)
        }
    }

    function removeFromArray(index) {
        return function(event) {
            onChange(value.filter((v, i) => i != index))
            if(tab === index) {
                setTab(0)
            }
        }
    }

    function addToArray() {
        onChange([...value, {
            idioma:'',
            nivelDominio:'',
            porcentaje:''
        }])
        setTab(value.length)
    }

    return (
        <React.Fragment>
            <h6>Idiomas</h6>
            <Nav tabs>
                {
                    value.map((e, i) => (
                        <NavItem key={i}>
                            <NavLink
                            onClick={() => setTab(i)}
                            className={tab == i ? 'active' : ''}>{i + 1}</NavLink>
                        </NavItem>
                    ))
                }                
                <NavItem>
                    <NavLink
                        onClick={addToArray}
                    >Agregar</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
                {
                    value.map((e,i) => (
                        <TabPane tabId={i} key={i}>
                            {
                                value.length > 1 ?
                                <Button onClick={removeFromArray(i)}>Eliminar</Button> : null
                            }
                            <FormGroup>
                                <Label>Idioma</Label>
                                <FastInput type="text" 
                                    value={e.idioma}
                                    onChange={handleChange('idioma', i)}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Nivel de dominio</Label>
                                <FastInput type="text" 
                                    value={e.nivelDominio}
                                    onChange={handleChange('nivelDominio', i)}/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Porcentaje Obtenido</Label>
                                <FastInput type="text" 
                                    value={e.porcentaje}
                                    onChange={handleChange('porcentaje', i)}/>
                            </FormGroup>                            
                        </TabPane>
                    ))
                }
            </TabContent>
        </React.Fragment>
    )   
}