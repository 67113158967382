import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '@bit/fergc.integrarh.fast-input'
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'

export default function DatosGeneralesFormExtra({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            onChange({...value, [name]:event.target.value})
        }
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Label>Competencias Laborales</Label>
                <FastInput type="textarea" 
                    value={value.competenciasLaborales}
                    onChange={handleChange('competenciasLaborales')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Áreas de interés</Label>
                <FastInput type="textarea" 
                    value={value.areasInteres}
                    onChange={handleChange('areasInteres')}
                    />
            </FormGroup>            
        </React.Fragment>
    )

}