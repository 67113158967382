import React, {useState, useEffect} from 'react'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import {usePostHttpRequest} from './Hooks'

export default function ConfirmPsicometrioModal({isOpen, onDone, onCancel, paso}) {

    const [working, doPost] = usePostHttpRequest();

    async function handleConfirm() {
        await doPost(`/prospecto/proceso/confirm`, {
            stepName: 'evaluacionPsicometrica',
            confirmacion: {}
        })
        onDone();
    }

    function handleCancel() {
        onCancel();
    }

    return (
        <Modal isOpen={isOpen} toggle={handleCancel}>
          <ModalHeader toggle={handleCancel}>Confirmar resultado</ModalHeader>
          <ModalBody>
            ¿Confirmar resultado de estudio psicométrico?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={working} onClick={handleConfirm}>Aceptar</Button>{' '}
            <Button color="secondary" disabled={working} onClick={handleCancel}>Cancelar</Button>
          </ModalFooter>
        </Modal>
    )
}