import React from 'react';
import {Button, Input, FormGroup, Label} from 'reactstrap';
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'
import {useFetch} from '../Hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

function Aceptado() {
    return (
        <div className="ResultadoForm">
            <p className="Bold">
                <span><FontAwesomeIcon className="FontGreen" icon={faCheck}></FontAwesomeIcon> </span>
                 !Felicidades has sido seleccionado!
            </p>
            <p>
                Nos permitimos informarle que previo análisis a su expediente dentro del proceso de
                reclutamiento su perfil ha sido aprobado por nuestra mesa de análisis.                    
            </p>
            <p>
                En los próximos días su reclutador se pondrá en contacto con usted para dar instrucciones
                al respecto.
            </p>
            <p>
                Agradecemos su diposición durante el proceso de reclutamiento y lo felicitamos por el resultado
                obtenido
            </p>
        </div>
    )
}

function Rechazado() {
    return (
        <div className="ResultadoForm">
            <p>
                Nos permitimos agradecerle su participación en nuestro proceso de reclutamiento, su expediente 
                fue sometido a una mesa de análisis por nuestros expertos y representante del cliente; le informarmos
                que por el momento su perfil no fue seleccionado.
            </p>
            <p>
                En los próximos días uno de nuestros reclutadores se pondrá en contacto con usted para hacer devolución
                de su expediente, o bien, invitarlo a participar en futuras requisiciones
            </p>
        </div>
    )
}


export default function ResultadoForm({resultado, isOpen, onComplete, onCancel}) {
    function handleClick() {
        onComplete();
    }

    return (
        <FlexColumn>
            {
                resultado.datos.resultado === 'aceptado' ? 
                <Aceptado></Aceptado> :
                resultado.datos.resultado === 'rechazado' ?
                <Rechazado></Rechazado> :
                null
            }                    
            <br/>
            <FlexRow justifyContent='center'>
                <Button className="btn-white" onClick={handleClick}>ENTENDIDO</Button>
            </FlexRow>
        </FlexColumn>
    )

}