
import React, {useState, useEffect} from 'react';
import axios from 'axios';

function useSession() {
    const [jwt, setJwt] = useState(localStorage.getItem('jwt'));  

        if(axios.interceptors.request.handlers.length == 0 && jwt) {
            console.log('ADDING INTERCEPTOR');            
            axios.interceptors.request.use(
              (config) => {
                config.headers['Authorization'] = `Bearer ${ jwt }`;
                return config;
              }, 

              (error) => {
                return Promise.reject(error);
              }
            );
        } else if(!jwt) {
            console.log('REMOVING INTERCEPTOR')
            axios.interceptors.request.handlers = [];
        }

    function logout() {
        localStorage.removeItem('jwt');
        window.location.href = '/login';
    }

    function login(jwt) {
        localStorage.setItem('jwt', jwt)
        window.location.href = '/app'
    }

    return [jwt, login, logout]

}


export {
    useSession
}