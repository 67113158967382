import React, {useState, useEffect} from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {usePostHttpRequest} from './shared/Hooks'
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row';
import LoginLeftImg from './images/login_left_img.png'
import HttpRequest from './shared/HttpRequest';

import './Login.css'
import LoginOptions from './LoginOptions';

const ERROR_LABELS = {
    too_many: 'Se ha enviado un correo para inicio de sesión recientemente, por favor revisa tu bandeja',
    not_found: 'No se encontró un proceso de reclutamiento para este correo',
    robot: 'Ha habido un error, por favor intenta nuevamente'
}

export default function Login({match}) {

    const [captchaToken, setCaptchaToken] = useState(null);
    const [email, setEmail] = useState('')
    const [result, setResult] = useState({
        error: '',
        success: false,
        verifying: false
    })
    const [working, doPost, response] = usePostHttpRequest();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LfN9bYUAAAAAC19OpP0mOi-neTq6HUGlJ29S3kK');
        document.body.appendChild(recaptchaScript);
    }, [])

    useEffect(() => {
        if(response && response.data) {
            const {success} = response.data;
            if(!success) {
                setResult({
                    error: ERROR_LABELS[response.data.reason],
                    success: false
                })
            } else {
                setResult({
                    error: '',
                    success: true
                })
            }
        }
    }, [response])

    useEffect(() => {
        async function verifyToken() {
            if(!match.params.token) {
                return;
            }
            setResult({
                error:'',
                success: false,
                verifying: true
            })
            try {
                const response = await HttpRequest.get(`/session/login/prospecto/${match.params.token}`);
                if(response.data.result.length === 1) {
                    const {token} = response.data.result[0];
                    login(token);
                } else {
                    setOptions(response.data.result)
                }
            } catch(e) {
                setResult({
                    success:false,
                    error: 'La liga es inválida. Por favor genere otra desde la página de inicio o solicitela a su reclutador'
                })
            }
        }

        verifyToken();
    }, [match.params.token])

    function login(token) {
        localStorage.setItem('jwt', token)
        window.location.href = '/app'
    }

    function handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute('6LfN9bYUAAAAAC19OpP0mOi-neTq6HUGlJ29S3kK', {action: 'login'}).then(function(token) {
               setCaptchaToken(token);
               doPost('/session/login/prospecto', {
                   token,
                   email
               })
            });
        });        
    }

    function handleChange(event) {
        setEmail(event.target.value)
    }

    return (
        <div className="loginBackground">
            <div className="loginContainer">
                {
                    options.length > 0 ?
                    <LoginOptions options={options} onSelected={login}></LoginOptions>
                    : 
                    <FlexRow>
                        <div className="loginLeftColumn">
                            <img src={LoginLeftImg} alt="login img" style={{width:'100%', height:'100%'}}/>
                        </div>                    
                        <Form onSubmit={handleSubmit} className="loginForm">                                                    
                            <FlexColumn justifyContent="center" alignItems="center">
                                {
                                    result.verifying ?
                                    <div style={{padding:'40px'}}>
                                        Validando acceso
                                    </div> :
                                    result.success ? 
                                    <div style={{padding:'40px'}}>
                                        Se ha enviado una liga a tu correo, por favor revisa tu bandeja
                                    </div> :
                                    <React.Fragment>
                                        <h5 style={{padding:'40px'}}>¡Felicidades, haz sido seleccionado para participar en una vacante!</h5>
                                        <FormGroup>
                                            <Label>Correo</Label>
                                            <Input type="email"
                                                placeholder="Correo" 
                                                value={email}
                                                onChange={handleChange}
                                                required/>                
                                        </FormGroup>
                                        <br/>
                                        <p className="FontRed"style={{textAlign:'center'}}>{result.error}</p>
                                        <Button type="submit" 
                                            disabled={working}
                                            className="btn-blue">Entrar</Button>
                                    </React.Fragment>
                                }                                
                            </FlexColumn>
                        </Form>                
                    </FlexRow>
                }                
            </div>
        </div>     
    )

}