import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '@bit/fergc.integrarh.fast-input'
import FlexColumn from '@bit/fergc.integrarh.flex-column'
import FlexRow from '@bit/fergc.integrarh.flex-row'

export default function DatosGeneralesFormGenerales({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            onChange({...value, [name]:event.target.value})
        }
    }

    return (
        <React.Fragment>
            <h6>Datos generales</h6>
            <FormGroup>
                <Label>Apellido paterno</Label>
                <FastInput type="text" 
                    value={value.paterno}
                    onChange={handleChange('paterno')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Apellido materno</Label>
                <FastInput type="text" 
                    value={value.materno}
                    onChange={handleChange('materno')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Nombre(s)</Label>
                <FastInput type="text" 
                    value={value.nombre}
                    onChange={handleChange('nombre')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Fecha de nacimiento</Label>
                <FastInput type="date" 
                    value={value.fechaNacimiento}
                    onChange={handleChange('fechaNacimiento')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Lugar de nacimiento</Label>
                <FastInput type="text" 
                    value={value.lugarNacimiento}
                    onChange={handleChange('lugarNacimiento')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Estado civil</Label>
                <FastInput type="text" 
                    value={value.estadoCivil}
                    onChange={handleChange('estadoCivil')}
                    />
            </FormGroup>
            <FormGroup>
                <Label>Número de seguridad social</Label>
                <FastInput type="text" 
                    value={value.nss}
                    onChange={handleChange('nss')}/>
            </FormGroup>
            <FormGroup>
                <Label>RFC</Label>
                <FastInput type="text" 
                    value={value.rfc}
                    onChange={handleChange('rfc')}/>
            </FormGroup>
            <FormGroup>
                <Label>CURP</Label>
                <FastInput type="text" 
                    value={value.curp}
                    onChange={handleChange('curp')}/>
            </FormGroup>
        </React.Fragment>
    )

}