import React, {useState, useEffect} from 'react'
import FlexRow from '@bit/fergc.integrarh.flex-row'
import {Button, Collapse, FormGroup, Label} from 'reactstrap';
import StepButton from '@bit/fergc.integrarh.step-button';
import DatosGeneralesFormFields from '../shared/prospecto/DatosGeneralesFormFields';
import EvaluacionPsicometricaFormFields from '../shared/prospecto/EvaluacionPiscometricaFormFields';
import ResultadoForm from '../shared/prospecto/ResultadoForm';
import FlexColumn from '@bit/fergc.integrarh.flex-column';
import HttpRequest from '../shared/HttpRequest'
import FastInput from '@bit/fergc.integrarh.fast-input'
import BasicStepMoreInfo from '../shared/BasicStepMoreInfo';
import ConfirmPsicometricoMoreInfo from '../shared/ConfirmPsicometricoMoreInfo'
import {useToggle} from '../shared/Hooks'


function InfoGeneralStepButton({step}) {
    const props = {
        className:'btn-transparent',
        disabled:step.status !== 'done' && step.status !== 'current' && step.nombre != 'infoGeneral',
        title:step.uiNombre,
        stepForm: DatosGeneralesFormFields,
        onComplete: function() {

        }
    }       
    return <StepButton {...props}>Ver</StepButton>    
}

function EvaluacionPsicometricaStepButton({step, stepForm}) {
    const props = {
        className:'btn-transparent',
        disabled:step.status !== 'done' && 
            step.status !== 'current' && 
            step.status !== 'waitingConfirmation',
        title:step.uiNombre,
        stepForm: EvaluacionPsicometricaFormFields,
        stepProps: {
            requisito: step.requisito
        },
        onComplete: function() {

        }
    }       
    return <StepButton {...props}>Ver</StepButton>    
}

function ResultadoStepButton({step}) {
    const props = {
        className:'btn-transparent',
        disabled:step.status !== 'done' && step.status !== 'current' && step.nombre != 'infoGeneral',
        title:step.uiNombre,
        stepForm: ResultadoForm,
        stepProps: {
            resultado: step
        },
        onComplete: function() {

        }
    }       
    return <StepButton {...props}>Ver</StepButton>    
}

export default function ProspectoProcesoStep({numero, paso, onChange, onEventRejected}) {

    const [collapsed, toggle] = useToggle(true);
    

    function ButtonForStep() {
        if(paso.nombre === 'infoGeneral')  {
            return <InfoGeneralStepButton step={paso}></InfoGeneralStepButton>
        }        
        
        if(paso.status !== 'current' && paso.status !== 'done' && paso.status !== 'waitingConfirmation') {
            return null;
        }

        if(paso.nombre === 'evaluacionPsicometrica' && paso.status === 'current') {
            return <EvaluacionPsicometricaStepButton step={paso}></EvaluacionPsicometricaStepButton>
        }

        if(paso.nombre === 'resultado' && paso.status == 'current') {
            return <ResultadoStepButton step={paso}></ResultadoStepButton>
        }

        if(paso.nombre === 'evaluacionPsicometrica') {
            return <Button className="btn-transparent" onClick={toggle}>Más información</Button>
        }
        return null;
    }    

    return (
        <div className="ProspectoProcesoStep">
            <FlexRow flexWrap="nowrap" className="ProspectoProcesoStepHeader">
                <div className="ProspectoProcesoStepSideBar"></div>         
                <div className="ProspectoProcesoStepNumber">{numero}</div>
                <div className="ProspectoProcesoStepTitle">{paso.uiNombre}</div>
                <FlexRow style={{padding:'10px', flex:1}} justifyContent="flex-end">
                    <ButtonForStep></ButtonForStep>
                </FlexRow>
            </FlexRow>
            <Collapse isOpen={!collapsed}>
                <FlexColumn className="ProspectoStepCollapsible">
                {
                    paso.nombre === 'evaluacionPsicometrica' ?
                    <ConfirmPsicometricoMoreInfo
                        paso={paso}
                        isOpen={!collapsed}
                        onAccept={onChange}/> : null
                }   
                </FlexColumn>
            </Collapse>            
        </div>
    )
}